import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";

export default function DialogBox(props) {
  const {
    showDialog = false,
    onClose = () => {},
    title = "",
    dialogContent = "",
    dialogActions = "",
    iconReq = false,
    iconContent="",
    outsideClickedDismiss = true,
    mWidth="md",
   // children,
  } = props;
  //console.log(props);
  const handleClose = (event) => {
    if (outsideClickedDismiss) {
      //console.log("handleClose");
      onClose(event);
    }
  };

  return (
    <div>
      {/* <Button variant="filled" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}

      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={mWidth}
        sx={{
            '& .MuiDialogTitle-root' : {
                backgroundColor: 'primary.datagridrow',
                color: 'white'
            }}
        }
      >
        <DialogTitle id="form-dialog-title">{iconReq && iconContent}<center><b>{title}</b></center></DialogTitle>        
        <DialogContent style={{ paddingTop: '10px' }} maxWidth={mWidth}>
          {dialogContent}
        </DialogContent>
          <DialogActions >
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >{dialogActions}</Grid></DialogActions>
      </Dialog>
    </div>
  );
}

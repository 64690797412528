import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TableBody, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogBox from "../global/DialogBox";
import { ComboSelMethod, SHOWRESTOFEWDATA, SHOWCOMBOOFFERS, getPreUrlAccordingToCart, CART_INITIAL_STATE, SUCCESS, OfferType, ItemStatus, SETCOMBOOFFERS, isEmpty, isNumberEmpty, isRoleAllowed, INVENTORYCREATE, printDebugLog, GETSINGLECONFIGS, ECARTAPP, printInfoLog } from "../../constants/constant";
import '../resturant/RestoProductStock.css'
import CustomDataGrid from "../global/CustomDataGrid";
import { snpAxios } from "../global/api";
import DismissButton from "../global/DismissButton";
import SnpButton from "../global/SnpButton";
import axios from "axios";

export default function Receipe() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackColor: '',
        snackVertical: '',
        snackHorizontal: '',
    };
    const INITIAL_RESTO_DATA = {
        id: "",
        amt: "",
        tname: "",
        name: "",
    };
    

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [restoData, setRestoData] = useState([]);
    const [listReceipe,setListReceipe] = useState([]);
    const [isEditProduct, setEditProduct] = useState(false);
    const [selectedRestoData, setSelectedRestoData] = useState(INITIAL_RESTO_DATA);

    useEffect(() => {
        //console.log("useEffect",CART_INITIAL_STATE)
        getRestoDetails();
    }, [storeActiveShopHash, storeApiUrl]);

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleDialogClose = () => {
        printInfoLog("close");
        setShowDialog(false);
    };
    const handleSubmit = () => {
        printDebugLog("handleSubmit");
    }

    const columns = [
        { field: 'receipeBy', headerName: 'Receipe By', width: 120 },
        { field: 'stockName', headerName: 'Name', width: 140 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon>
                    </EditIcon>
                );
            }
        }
    ];
    const getRestoDetails = async () => {
        setLoader(true);
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWRESTOFEWDATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                getRceipeoDetails();
                if (response.data.status === SUCCESS) {
                    setRestoData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                getRceipeoDetails();
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };


    const getRceipeoDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + "get-item-receipes";
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                setLoader(false);
                if (response.data.status === SUCCESS) {
                    setListReceipe(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setLoader(false);
                printDebugLog("errr ", error);
                //window.location.href = "#/error";
            });
    };

    
    const handleCellClick = (param, event) => {
        
        setShowDialog(true);
    };
    
    

    const renderDialogContent = () => {
        return (
            <div>
                <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ border: "1px solid black", padding: "5px 5px", margin: "5px" }}>
                    <Grid item md={12} sm={12} xs={12} lg={12} >
                        <Autocomplete
                            id="restoSelect"
                            variant="filled"
                            size="small"
                            fullWidth
                            options={restoData}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.tname}
                            value={selectedRestoData}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    <Typography variant="p" style={{ color: (option.added ? "#7f8485" : "#000000") }}>
                                        {option.tname}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Food Item"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                    </Grid>
                    
                </Grid>
            </div>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                
                    <SnpButton variant="contained" color="primary" onClick={handleSubmit}>
                        {!isEditProduct ? "Add" : "Update"}
                    </SnpButton>
                
                
                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    };

    const renderSnpContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: 10 }}>
                        <Button variant="contained" color="primary" sx={{ boxShadow: 10 }}
                            style={{ float: "right" }}
                            onClick={(event) => {
                                //console.log("Combo",restoData);
                                if (isRoleAllowed(INVENTORYCREATE)) {                       
                                    setShowDialog(true);
                                } else {
                                    setSnpComponent({
                                        ...snpComponent,
                                        showDialog: true,
                                        title: "",
                                        iconReq: true,
                                        negativeReq: true,
                                        subTitle: "You Are not allowed to Add Receipe Details.Contact the Owner",
                                        negativeAction: "Got It!",
                                    });
                                }
                            }}>
                            Add New Receipe Entry
                        </Button></div>

                    <CustomDataGrid
                        rows={listReceipe}
                        columns={columns}
                        loading={isLoader}
                    />
                </Grid>
                <DialogBox showDialog={showDialog}
                    title="Receipe Entry"
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()} />
            </Grid>
        );
    };

    return (
        <SnpComponent
                content={renderSnpContent()}
                showDialog={snpComponent.showDialog}
                isLoader={isLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
                iconReq={snpComponent.iconReq}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.handleSnpSubmit}
                gui={snpComponent.gui}
                // bgColor='#01333e'
                snackColor={snpComponent.snackColor}
                snackVertical={snpComponent.snackVertical}
                snackHorizontal={snpComponent.snackHorizontal}
            />
    );
}
// CustomButton.js
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const SnpButton = styled(Button)(({ theme,  mColor = '#ffffff' , mBgColor = 'primary' }) => ({
  backgroundColor: mBgColor,  // Default white background
  borderRadius: '20px',
  color: mColor,             // Primary text color
  '&:hover': {
    backgroundColor: '#000f12', // Slightly darker on hover
  },
  textTransform: "none",
}));

export default SnpButton;

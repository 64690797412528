import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TableBody, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogBox from "../global/DialogBox";
import { ComboSelMethod, SHOWRESTOFEWDATA, SHOWCOMBOOFFERS, getPreUrlAccordingToCart, CART_INITIAL_STATE, SUCCESS, OfferType, ItemStatus, SETCOMBOOFFERS, isEmpty, isNumberEmpty, isRoleAllowed, INVENTORYCREATE, printDebugLog, GETSINGLECONFIGS, ECARTAPP, printInfoLog } from "../../constants/constant";
import '../resturant/RestoProductStock.css'
import CustomDataGrid from "../global/CustomDataGrid";
import imageUploadService from "../../service/imageUploadService";
import { snpAxios } from "../global/api";
import DismissButton from "../global/DismissButton";
import SnpButton from "../global/SnpButton";
import axios from "axios";

export default function ComboOffer() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };

    const INITIAL_COMBO_OFFER = {
        id: "",
        comboName: "",
        statusId: "",
        attachment: "",
        offerTypeId: "",
        itemList: [],
        finalAmount: 0.0,
        amount: 0.0,
        offerAmt: 0.0,
        offerType: OfferType.No_Offer,
        status: ItemStatus.ACTIVE,
        cartVisible: false,
    };
    const INITIAL_COMBO_SEL = {
        id: "",
        index: -1,
        itemId: "",
        itemName: "",
        name: "",
        itemCount: 0.0,
        pastAmt: 0.0,
        amount: 0.0,
        type: ComboSelMethod.FullPay,
        typeId: "",
        deleted: false,
    };
    const INITIAL_SEL_METHOD = {
        id: 0,
        value: "",
    };
    const INITIAL_RESTO_DATA = {
        id: "",
        amt: "",
        tname: "",
        name: "",
    };

    const DOTOTALOPERATION = {
        DEL:0,
        ADD:1,
        EDIT:2,
    }

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [showDialog, setShowDialog] = useState(false);
    const [isEditProduct, setEditProduct] = useState(false);

    const [comboOffer, setComboOffer] = useState(INITIAL_COMBO_OFFER);
    const [listComboOffer, setListComboOffer] = useState([]);

    const [selMethod, setSelectMethod] = useState(INITIAL_SEL_METHOD);
    const [listSelMethod, setListSelMethod] = useState([]);

    const [selectedComboSel, setSelectedComboSel] = useState(INITIAL_COMBO_SEL);
    const [listComboSel, setListComboSel] = useState([]);
    const [listComboDel, setListComboDel] = useState([]);

    const [selectedRestoData, setSelectedRestoData] = useState(INITIAL_RESTO_DATA);
    const [restoData, setRestoData] = useState([]);
    const [tempRestoData, setTempRestoData] = useState([]);
    const [uploadedDocument, setUploadedDocument] = useState([]);
    const [ecartApp, setEcartApp] = useState(false);

    useEffect(() => {
        //console.log("useEffect",CART_INITIAL_STATE)
        getSingleConfigs(ECARTAPP);
        getRestoDetails();
    }, [storeActiveShopHash, storeApiUrl]);

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleDialogClose = () => {
        console.log("handleDialogClose");
        setEditProduct(false);
        setComboOffer(INITIAL_COMBO_OFFER);
        setListComboSel([]);
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedRestoData(INITIAL_RESTO_DATA);
        setSelectedComboSel(INITIAL_COMBO_SEL);
        setTempRestoData([]);
        setShowDialog(false);
        getComboOffers();
        setListComboDel([]);
    };

    const columns = [
        {
            field: "attachment",
            headerName: "Image",
            width: 90,
            renderCell: (rowData) => (
                <div>
                    <img
                        alt=""
                        style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                        src={storeApiUrl.BASE_IMAGE_URL + rowData.row.attachment}
                    ></img>
                </div>
            ),
        },
        { field: 'comboName', headerName: 'Combo Name', width: 220 },
        { field: 'offerTypeId', headerName: 'OfferType', width: 90 },
        { field: 'offerAmt', headerName: 'OfferAmt.', width: 90 },
        { field: 'amount', headerName: 'Amt.', width: 90 },
        { field: 'finalAmount', headerName: 'f.Amt', width: 90 },
        { field: 'statusId', headerName: 'Status', width: 100 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(INVENTORYCREATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                setSnpComponent({
                                    ...snpComponent,
                                    showDialog: true,
                                    title: "",
                                    iconReq: true,
                                    negativeReq: true,
                                    subTitle: "You Are not allowed to Update Combo Details.Contact the Owner",
                                    negativeAction: "Got It!",
                                })
                            }
                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];
    const getRestoDetails = async () => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWRESTOFEWDATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                getComboOffers();
                if (response.data.status === SUCCESS) {
                    setRestoData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                getComboOffers();
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };


    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === ECARTAPP) {
                        setEcartApp(response.data === 'Yes');
                    }
                }
            }).catch((error) => {
                printInfoLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getComboOffers = () => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWCOMBOOFFERS;

        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        snpAxios.post(cUrl, data)
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                //console.log("getComboOffers", response.data);
                if (response.data.status === SUCCESS) {
                    var array = [...response.data.jcJson.value_1];
                    array.splice(0, 1);
                    setListSelMethod(array);
                    setListComboOffer(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("getComboOffers error ", error);
            });
    };
    const handleImageUpload = async () => {
        if (!uploadedDocument) {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            })
            handleSubmit("");
        } else {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            })
            let formData = new FormData();
            formData.append('fnm', CART_INITIAL_STATE.apiToken);
            formData.append("file", uploadedDocument[0]);
            await imageUploadService.upload(storeApiUrl.BACKEND_BASE_URL, getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
                //console.log("handleImageUpload", response.data);
                if (response.data.folderName) {
                    handleSubmit(response.data.folderName);
                } else {
                    handleSubmit("");
                }

            }
            ).catch(error => {
                handleSubmit("");
                console.log("handleImageUpload = > ", error);
                // window.location.href = "#/error";
            });
        }
    };
    const handleSubmit = (folderName) => {
        //console.log("handleSubmit=1>",listComboSel , comboOffer);
        let isError = false;
        if (isEmpty(comboOffer.comboName)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Combo Name",
                snackBarOpen: true,
            })
        }

        if (listComboSel.length <= 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Items",
                snackBarOpen: true,
            })
        }
        if (!isError) {
            let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SETCOMBOOFFERS;
            let filledData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    id: comboOffer.id,
                    comboName: comboOffer.comboName,
                    itemList: listComboSel,
                    status: parseInt(comboOffer.status),
                    offerType: comboOffer.offerType,
                    offferAmt: comboOffer.offerAmt,
                    amount: comboOffer.amount,
                    finalAmount: comboOffer.finalAmount,
                    shopHash: storeActiveShopHash.id,
                    attachment: folderName,
                    deletedItems: listComboDel,
                    cartVisible:comboOffer.cartVisible,
                }
            };
            //console.log("handleSubmit => ", filledData);
            handleDialogClose();
            snpAxios.post(catgUrl, filledData)
                .then(response => {
                    //console.log("responses => ", response.data);
                    if (response.data.status === SUCCESS) {
                        getComboOffers();
                    }
                }).catch(error => {
                    console.log("error : ", error);
                    // window.location.href = "#/error";
                });
            handleDialogClose();
        }
    };

    const changeInRestoData = (name, added) => {
        var array = [...tempRestoData];
        var index = array.findIndex(singleValue => singleValue.tname === name);
        array[index].added = added;
        setTempRestoData(array);
    }
    function checkIfItemPresent(name, array) {
        //console.log("checkIfItemPresent ",array,name);
        return array.find((key) => {
            return (key.itemName === name);
        });
    }
    const handleCellClick = (param, event) => {
        let itemList = event.row.itemList;
        //console.log("handleCellClick=>",itemList);
        const newArray = restoData.map(a => ({ ...a, added: checkIfItemPresent(a.tname, itemList) }));//Because of Shallow Copy,I used this method to convert to deep Copy
        //console.log("handleCellClick=>",newArray);
        setListComboSel(itemList);
        setTempRestoData(newArray);
        setEditProduct(true);
        setComboOffer({
            ...comboOffer, ...event.row
        });
        setShowDialog(true);
    };
    const getKeyByValue = (value) => {
        //console.log("getKeyByValue",ComboSelMethod,value);
        return Object.keys(ComboSelMethod).find(key => ComboSelMethod[key] === value);
    }
    const handleSelectChange = (event, resto) => {
        //console.log("handleSelectChange",resto);
        if (resto != null) {
            if (resto.added) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Item " + resto.tname + " is already added",
                })
            } else {
                let comboselMethodVal = getKeyByValue(ComboSelMethod.FullPay);
                //console.log("comboselMethodVal",comboselMethodVal);
                setSelectedComboSel({
                    ...selectedComboSel,
                    itemId: resto.id,
                    itemName: resto.tname,
                    name: resto.name,
                    itemCount: 1.0,
                    pastAmt: parseFloat(resto.amt),
                    amount: parseFloat(resto.amt),
                    index: -1,
                    type: ComboSelMethod.FullPay,
                    typeId: comboselMethodVal,
                    id: "",
                });
                setSelectedRestoData({
                    ...selectedRestoData,
                    id: resto.id,
                    amt: parseFloat(resto.amt),
                    tname: resto.tname,
                    name: resto.name,
                });
                setSelectMethod({
                    ...selMethod,
                    id: ComboSelMethod.FullPay,
                    value: comboselMethodVal,
                });
            }
        } else {
            setSelectMethod(INITIAL_SEL_METHOD);
            setSelectedRestoData(INITIAL_RESTO_DATA);
            setSelectedComboSel(INITIAL_COMBO_SEL);
        }
    };

    const handleSelectMethod = (event, data) => {
        //console.log("handleSelectMethod",data);
        if (data != null) {
            setSelectMethod(data);
            setSelectedComboSel({
                ...selectedComboSel,
                type: data.id,
                typeId: data.value,
                amount: parseFloat(selectedComboSel.pastAmt) ,
                itemCount: 1,
            });
        } else {
            setSelectMethod({
                ...selMethod,
                id: ComboSelMethod.FullPay,
                value: getKeyByValue(ComboSelMethod.FullPay),
            });
            setSelectedComboSel({
                ...selectedComboSel,
                type: ComboSelMethod.FullPay,
                typeId: getKeyByValue(ComboSelMethod.FullPay),
                amount: parseFloat(selectedComboSel.pastAmt),
                itemCount: 1,
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'imgPath') {
            let file = event.target.files[0];
            if (!file) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Selected! Please Try Again",
                    snackBarOpen: true,
                })
            } else if (file.size > 5242880) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Size Should be less than 5Mb",
                    snackBarOpen: true,
                })
            } else if (!(file.type.includes("image"))) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select Image",
                    snackBarOpen: true,
                })
            } else {
                setUploadedDocument(prevDocuments => [...prevDocuments, file]);
            }
        } else if (name === 'itemCount') {
            let curr = parseFloat(value) * parseFloat(selectedComboSel.pastAmt);
            setSelectedComboSel({
                ...selectedComboSel,
                amount: curr,
                itemCount: value,
            });
        } else if (name === 'amount') {
            setSelectedComboSel({
                ...selectedComboSel,
                amount: parseFloat(value),
            });
        } else if (name === 'comboName') {
            setComboOffer({
                ...comboOffer,
                comboName: value,
            });
        } else if (name === 'offerType') {
            doTotal(null, parseInt(value), comboOffer.offerAmt, DOTOTALOPERATION.ADD);
        } else if (name === 'offerAmt') {
            doTotal(null, comboOffer.offerType, parseFloat(value), DOTOTALOPERATION.ADD);
        } else if (name === 'status') {
            setComboOffer({
                ...comboOffer,
                status: value,
            });
        }
    };

    const getNewFinalAmount = () => {
        let mFinalAmount = 0.0;
        if (comboOffer.offerType === OfferType.No_Offer)
            mFinalAmount = parseFloat(comboOffer.finalAmount);
        else if (comboOffer.offerType === OfferType.Flat)
            mFinalAmount = parseFloat(comboOffer.finalAmount) + comboOffer.offerAmt;
        else if (comboOffer.offerType === OfferType.Percentage)
            mFinalAmount = parseFloat(comboOffer.finalAmount) + ((parseFloat(comboOffer.finalAmount) * parseFloat(comboOffer.offerAmt)) / 100);
        else if (comboOffer.offerType === OfferType.Multi)
            mFinalAmount = parseFloat(comboOffer.finalAmount);
        return mFinalAmount;
    }

    const doTotal = (mSelectedComboSel, mOfferType, mOfferAmt, operation) => {
        printDebugLog("init dototal", "mOfferType: " + mOfferType, "mOfferAmt: " + mOfferAmt, "operation: " + operation);
        printDebugLog("doTotal(comboOffer)", comboOffer);
        if (isNumberEmpty(mOfferAmt))
            mOfferAmt = 0.0;
        if (isNumberEmpty(mOfferType))
            mOfferType = OfferType.No_Offer;
        let mAmount = parseFloat(comboOffer.amount);
        let mFinalAmount = getNewFinalAmount();
        if (operation === DOTOTALOPERATION.DEL ||
            operation === DOTOTALOPERATION.EDIT) {
            const oldComboSel = listComboSel[mSelectedComboSel.index];
            mAmount = mAmount - (parseFloat(oldComboSel.pastAmt) * parseFloat(oldComboSel.itemCount))
            mFinalAmount = mFinalAmount - parseFloat(oldComboSel.amount);
        }
        let isMult = false;
        if (mSelectedComboSel !== null && operation > DOTOTALOPERATION.DEL) {
            mAmount = mAmount + (parseFloat(mSelectedComboSel.pastAmt) * parseFloat(mSelectedComboSel.itemCount));
            mFinalAmount = mFinalAmount + parseFloat(mSelectedComboSel.amount);
            isMult = mSelectedComboSel.type === ComboSelMethod.OfferPay;
        } else if (mSelectedComboSel !== null && operation === DOTOTALOPERATION.DEL) {
            isMult = listComboSel.some(item => item.type === mSelectedComboSel.type === ComboSelMethod.OfferPay && item.index !== mSelectedComboSel.index);
        }
        if (!isMult) {
            if (mOfferType === OfferType.Multi && !isMult) {
                mOfferType = OfferType.No_Offer;
            }
            if (mOfferType === OfferType.No_Offer) {
                mOfferAmt = 0.0;
            } else if (mOfferType === OfferType.Flat)
                mFinalAmount = mFinalAmount - mOfferAmt;
            else if (mOfferType === OfferType.Percentage)
                mFinalAmount = mFinalAmount - ((mFinalAmount * mOfferAmt) / 100);
        } else {
            mOfferAmt = 0.0;
            mOfferType = OfferType.Multi;
        }

        setComboOffer({
            ...comboOffer,
            offerAmt: parseFloat(mOfferAmt),
            offerType: Number(mOfferType),
            amount: parseFloat(mAmount.toFixed(2)),
            finalAmount: parseFloat(mFinalAmount.toFixed(2)),
        })

    }

    const handleAddOffer = (event) => {
        printDebugLog("handleAddOffer", selectedComboSel);
        if (selectedComboSel.index >= 0) {
            printDebugLog("handleAddOffer Edit", listComboSel);
            let listComboSelCopy = listComboSel;
            if (selectedComboSel.index >= 0 && listComboSel[selectedComboSel.index]) {
                doTotal(selectedComboSel, comboOffer.offerType, comboOffer.offerAmt, DOTOTALOPERATION.EDIT);
                listComboSelCopy[[selectedComboSel.index]] = { ...selectedComboSel }
                setListComboSel([...listComboSelCopy]);
            }
        } else {
            //let size = (listComboSel.length === 0) ? 0 : (listComboSel.length - 1);
            selectedComboSel.index = listComboSel.length;
            //console.log("handleAddOffer Add",selectedComboSel);
            doTotal(selectedComboSel, comboOffer.offerType, comboOffer.offerAmt, DOTOTALOPERATION.ADD);
            changeInRestoData(selectedComboSel.itemName, true);
            setListComboSel([
                ...listComboSel, selectedComboSel
            ]);
        }
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedRestoData(INITIAL_RESTO_DATA);
        setSelectedComboSel(INITIAL_COMBO_SEL);
    };

    const handleDelOffer = (e) => {
        printDebugLog("handleDelOffer", selectedComboSel);
        var listComboSelCopy = [...listComboSel];
        listComboSelCopy.splice(selectedComboSel.index, 1);
        setListComboSel([...listComboSelCopy]);
        doTotal(selectedComboSel, comboOffer.offerType, comboOffer.offerAmt, DOTOTALOPERATION.DEL);
        changeInRestoData(selectedComboSel.itemName, false);
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedRestoData(INITIAL_RESTO_DATA);
        setSelectedComboSel(INITIAL_COMBO_SEL);
        if (!isEmpty(comboOffer.id) && !isEmpty(selectedComboSel.id)) {
            printDebugLog("handleDelOffer true");
            setListComboDel(prevList => [...prevList, selectedComboSel.id]);
        }
    };

    const handleTableEditClick = (mSelectedComboSel) => {
        //console.log("handleTableEditClick",mSelectedComboSel);
        setSelectedComboSel(mSelectedComboSel);
        setSelectedRestoData({
            ...selectedRestoData,
            id: mSelectedComboSel.itemId,
            amt: mSelectedComboSel.amount,
            tname: mSelectedComboSel.itemName,
            name: mSelectedComboSel.name,
        });
        setSelectMethod({
            ...selMethod,
            id: mSelectedComboSel.type,
            value: mSelectedComboSel.typeId,
        });
    };

    

    const renderDialogContent = () => {
        return (
            <div>
                <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ border: "1px solid black", padding: "5px 5px", margin: "5px" }}>
                    <Grid item md={6} sm={6} xs={12} lg={6} >
                        <Autocomplete
                            id="restoSelect"
                            variant="filled"
                            size="small"
                            options={tempRestoData}
                            disabled={selectedComboSel.index >= 0}
                            onChange={handleSelectChange}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.tname}
                            value={selectedRestoData}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    <Typography variant="p" style={{ color: (option.added ? "#7f8485" : "#000000") }}>
                                        {option.tname}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Food Item"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                    </Grid>
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="pastAmt"
                                disabled
                                label="Amount"
                                name="pastAmt"
                                value={selectedComboSel.pastAmt}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 &&
                        <Grid item md={7} sm={8} xs={12} lg={7} >
                            <Autocomplete
                                id="type"
                                variant="filled"
                                size="small"
                                options={listSelMethod}
                                onChange={handleSelectMethod}
                                defaultValue={selMethod}
                                getOptionLabel={(option) => option.value}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option.value}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pay Type"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="itemCount"
                                type="number"
                                inputMode="numeric"
                                label="Enter Quantity"
                                name="itemCount"
                                onChange={handleInputChange}
                                value={selectedComboSel.itemCount}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="amount"
                                type="number"
                                label="Enter New Amount"
                                inputMode="numeric"
                                name="amount"
                                onChange={handleInputChange}
                                disabled={!(selectedComboSel.type === ComboSelMethod.OfferPay)}
                                value={selectedComboSel.amount}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6}>
                            <center>
                                <SnpButton variant="filled" mBgColor={selectedComboSel.index >= 0 ? "#13012b" : "#01333e"} onClick={handleAddOffer}>
                                    {selectedComboSel.index >= 0 ? "Modify" : "Add"}
                                </SnpButton>
                            </center>
                        </Grid>
                    }

                    {
                        selectedComboSel.index >= 0 && <Grid item md={6} sm={6} xs={12} lg={6}>
                            <center>
                                <SnpButton mBgColor="#2b0101" variant="filled" onClick={handleDelOffer}>
                                    Delete
                                </SnpButton>
                            </center>
                        </Grid>
                    }
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className="rate_card">
                        <TableHead>
                            <TableRow style={{ backgroundColor: "#301800" }}>
                                <TableCell align="center">Food Name</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">P.Amt</TableCell>
                                <TableCell align="center">N.Amt</TableCell>
                                <TableCell align="center">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(listComboSel).map((val, index) => {
                                return (
                                    <TableRow
                                        className="rate_card--row"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center">{val.name}</TableCell>
                                        <TableCell align="center" >{val.itemCount}</TableCell>
                                        <TableCell align="center" >{val.typeId}</TableCell>
                                        <TableCell align="center" >&#8377;{val.pastAmt}</TableCell>
                                        <TableCell align="center" >&#8377;{val.amount}</TableCell>
                                        <TableCell align="center" ><EditIcon onClick={() => handleTableEditClick(val)} /></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{
                    mt: 1,
                }}>
                    {comboOffer.attachment &&
                        <Grid item md={2} xs={12} lg={2} sm={2}>
                            <img
                                alt=""
                                style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                                src={storeApiUrl.BASE_IMAGE_URL + comboOffer.attachment}
                            />

                        </Grid>
                    }
                    <Grid item md={4} xs={12} lg={4} sm={4}>
                        <TextField
                            accept="image/*"
                            id="imgPath"
                            name="imgPath"
                            label=""
                            multiple
                            type="file"
                            autoHighlight
                            size="small"
                            helperText="Upload Image(If Any and it should be less than 5Mb.)"
                            onChange={handleInputChange}
                            fullWidth
                        /></Grid>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                id="comboName"
                                label="Enter Combo Name"
                                variant="filled"
                                size="small"
                                name="comboName"
                                value={comboOffer.comboName}
                                onChange={handleInputChange}
                                style={{ width: "40%" }}
                            />
                        </div>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <FormLabel component="legend">Any Offer :</FormLabel>
                        <RadioGroup row aria-label="offerType" name="offerType" value={comboOffer.offerType.toString()} onChange={handleInputChange}>
                            <FormControlLabel disabled value={OfferType.Multi.toString()} control={<Radio />} label="Multi" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.Flat.toString()} control={<Radio />} label="Flat" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.Percentage.toString()} control={<Radio />} label="Percentage" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                        </RadioGroup>
                    </Grid>
                    {
                        (comboOffer.offerType > 1 && comboOffer.offerType < 6) &&
                        <Grid item md={8} sm={8} xs={12} lg={8}>
                            <TextField
                                required
                                id="offerAmt"
                                name="offerAmt"
                                variant="filled"
                                size="small"
                                label="Enter Offer Value"
                                value={comboOffer.offerAmt.toString()}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                            /> </Grid>
                    }
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        {
                            comboOffer.finalAmount === comboOffer.amount
                                ? <Typography variant="body1" style={{
                                    color: "#02056e",
                                    fontWeight: 'bold', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "16px"
                                }}>
                                    Selling Price:&#8377;{comboOffer.amount}
                                </Typography>
                                :
                                <Typography variant="body1" style={{
                                    color: "#02056e",
                                    fontWeight: 'bold', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "16px"
                                }}>
                                    Selling Price:
                                    <span style={{ textDecoration: "line-through", marginRight: "8px" }}>
                                        &#8377;{comboOffer.amount}
                                    </span>
                                    <span style={{ color: "red", fontSize: "18px" }}>
                                        &#8377;{comboOffer.finalAmount}
                                    </span>
                                </Typography>
                        }
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Enter Item Status</FormLabel>
                            <RadioGroup aria-label="status" name="status" value={comboOffer.status.toString()} onChange={handleInputChange} row={true}>
                                <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                                <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {
                        ecartApp && <Grid md={5} xs={12} sm={6} lg={4}>
                            <center>
                                <FormControlLabel name="cartVisible"
                                    checked={comboOffer.cartVisible} disabled={(comboOffer.status === ItemStatus.ACTIVE) ? false : true} control={<Checkbox />} onChange={() => {
                                        let pVal = !comboOffer.cartVisible;
                                        setComboOffer({
                                            ...comboOffer,
                                            cartVisible: pVal,
                                        })
                                    }} label={<><p style={{
                                        whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                                    }}>Visible to end customer</p></>} />
                            </center>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    listComboSel.length > 0 && <SnpButton variant="contained" color="primary" onClick={handleImageUpload}>
                        {!isEditProduct ? "Add" : "Update"}
                    </SnpButton>
                }
                
                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    };

    const renderSnpContent = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: 10 }}>
                        <Button variant="contained" color="primary" sx={{ boxShadow: 10 }}
                            style={{ float: "right" }}
                            onClick={(event) => {
                                //console.log("Combo",restoData);
                                if (isRoleAllowed(INVENTORYCREATE)) {
                                    const newArray = restoData.map(a => ({ ...a }));//Because of Shallow Copy,I used this method to convert to deep Copy
                                    //console.log("Combo 2",newArray);
                                    setTempRestoData(newArray);
                                    setShowDialog(true);
                                } else {
                                    setSnpComponent({
                                        ...snpComponent,
                                        showDialog: true,
                                        title: "",
                                        iconReq: true,
                                        negativeReq: true,
                                        subTitle: "You Are not allowed to Add Combo Details.Contact the Owner",
                                        negativeAction: "Got It!",
                                    });
                                }
                            }}>
                            Add New Combo Offer
                        </Button></div>

                    <CustomDataGrid
                        rows={listComboOffer}
                        columns={columns}
                        loading={snpComponent.isLoader}
                    />
                </Grid>
                <DialogBox showDialog={showDialog}
                    title="Combo Offer"
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()} />
            </Grid>
        );
    };

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            title={snpComponent.title}
            isLoader={snpComponent.isLoader}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={(e) => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            content={renderSnpContent()}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={""}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={""}
        />
    );
}
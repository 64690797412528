import * as React from 'react';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from "@mui/material/Container";
import { Grid, TextField, Box, Button, Autocomplete, Typography, Avatar } from "@mui/material";
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import Configuration from '../configuration/Configuration';
import Category from '../Category';
import ProductEntry from '../product/ProductEntry';
import SupplierInfo from '../supplier/SupplierInfo';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Employee from '../employee/Employee';
import StockEntry from '../stockentry/StockEntry';
import Conversion from '../customunit/Conversion';
import { CART_INITIAL_STATE, CartDets, LOGOUT, setLogout,  
  isRoleAllowed, ADMINRIGHTS, SUPPLIERCREATE, SALESRIGHTS,  CUSTCREATE, 
  isFeatureAllowed, EMPMGMT, INVSTOCKMGMT, BILLMGMT, THEMEMGMT, ACTMGMT, 
  CHANGEPASSWORD, SUCCESS, COMPONENT_PARAMS, GETPRODDETAILS, ACCVIEW, isNumberEmpty, UPDATEHOMEDELIVERY, isEmpty, 
  ROLEUPDATE, DashboardComponent,  COMBOMGMT,  CUSTOMIZEDCONFIG,  
  offline_enable, ORDERRECEIVED, InternalBillType, printDebugLog, 
  CUSTOMERAPP, SUPMGMT, SHOWITEMWISESALES, Print_Log, printInfoLog, test_environment_mode, 
  RECPMGMT,
  OWNERDASHBOARD,
  FOODITEMPRODUCTMGMT,
  CUSTOMUNITMGMT,
  COMBOFOODITEMGMT} from '../../constants/constant';
import RestoProductStock from '../resturant/RestoProductStock';
import axios from "axios";
import BillingModule from '../billing/BillingModule';
import BillingTable from '../billing/BillingTable';
import ThemeManager from '../theme/ThemeManager';
import Profile from '../profile/Profile';
import ProductServiceCharge from '../product/ProductServiceCharge';
import Customer from '../customer/Customer';
import Accounting from '../accounting/Accounting';
import Loader from '../global/loader/Loader';
import { Navigate } from 'react-router-dom';
import ConfirmDialog from '../global/ConfirmDialog';
import DialogBox from "../global/DialogBox";
import "./Dashboard.css";
import './../chart/Chart.css';
import BookTable from '../booktable/BookTable';
import ComboOffer from '../combooffer/ComboOffer';
import RestoBillingModule from '../billing/RestoBillingModule';
import { useDispatch, useSelector } from 'react-redux';
//import Slide from '@mui/material/Slide';
import ShowBills from '../billing/ShowBills';
import * as websocket from 'websocket';
import { sideBarNavItems } from '../sidebar/sidebarNavItems';
import HelpModule from '../help/HelpModule';
import OwnerDashboard from './OwnerDashboard';
import { setCartType } from '../../actions';
import RiderDashBoard from '../rider/RiderDashboard';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
//import NotificationSound from "./notification-sound.mp3";
import NotificationSound from "./NotificationSound";
import { snpAxios } from '../global/api';
import ItemSaleDashboard from './ItemSaleDashboard';
import RestoStockInformation from '../resturant/RestoStockInformation'
import ReturnWasteBill from '../billing/ReturnWasteBill';

import utilities from '../../utilities/utilities';
import { getQueryParam } from '../../utilities/queryParamUtils';
import SnpButton from '../global/SnpButton';
import DismissButton from '../global/DismissButton';
import Receipe from '../receipe/Receipe';
export default function Dashboard(props) {
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
//  const audioPlayer = React.useRef(null);
  const [open, setOpen] = React.useState(true);
  const [dashboardUtils, setDashboardUtils] = React.useState({
    dIndex: DashboardComponent.REPORTMANAGEMENT,
    show: false,
    isDataLoaded: true,
    isLogout: false,
    showDialog: false,
    title: "",
    subTitle: "",
    negativeReq: true,
    negativeAction: "",
    positiveReq: false,
    positiveAction: "",
    clickIndex: DashboardComponent.REPORTMANAGEMENT,
    iconReq: true,
    passWordDialog: false,
    lockDialog: false,
    oldPassWord: "",
    newPassWord: "",
    isError: false,
    errMsg: "",
    onHandleCancel: () => { },
    outsideClickedConfirmDismiss: true,
    cancelConfirmExtra: false,
  });


  const toggleDrawer = () => {
    setOpen(!open);
  };

  

  const handleDialogClose = () => {
    //console.log("handleDialogclose");
    setDashboardUtils({
      ...dashboardUtils,
      showDialog: false,
      subTitle: "",
      negativeAction: "",
      positiveReq: false,
      title: "",
      show: true,
      clickIndex: DashboardComponent.REPORTMANAGEMENT,
      dIndex: (storeActiveShopHash.cartType === CartDets.Resto) ? DashboardComponent.BILLDETAILS : DashboardComponent.DASBOARD,
      iconReq: true,
      searchDialog: false,
    });
  };

  const handlePassWordDialogClose = () => {
    //console.log("handleDialogclose");
    let index = (storeActiveShopHash.cartType === CartDets.Resto) ? DashboardComponent.BILLDETAILS : DashboardComponent.DASBOARD;
    setDashboardUtils({
      ...dashboardUtils,
      passWordDialog: false,
      show: true,
      dIndex: index,
      newPassWord: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //console.log("handleInputChange",name , value);
    setDashboardUtils({
      ...dashboardUtils,
      [name]: value,
    });
  };

  const goToBilling = (data, sBillNo, type) => {
    //console.log("goToBilling", data);
    COMPONENT_PARAMS.tableProps = data;
    COMPONENT_PARAMS.sBillNo = sBillNo;
    COMPONENT_PARAMS.type = type;
    if (storeActiveShopHash.cartType === CartDets.Resto)
      setComponentIndex(DashboardComponent.RESTOBILLINGMODULE);
    else
      setComponentIndex(DashboardComponent.BILLINGMANAGEMENT);
  };

  const showDialogBox = (msg) => {
    setDashboardUtils({
      ...dashboardUtils,
      show: false,
      showDialog: true,
      subTitle: msg,
      negativeAction: "Got It!",
    });
  };

  const showDialogBoxWithPaper = (msg) => {
    setDashboardUtils({
      ...dashboardUtils,
      showDialog: true,
      subTitle: msg,
      negativeAction: "Got It!",
    });
  };
  



  const setComponentIndex = (index) => {
    console.log("setComponentIndex-1",index);
    if (index !== DashboardComponent.LOGOUTMESSAGE 
          && index !== DashboardComponent.PASSWORDDIALOG 
          && index !== DashboardComponent.SEARCHDIALOG 
          && index !== DashboardComponent.LOCKDIALOG) {
      //console.log("setComponentIndex-1",index);
      setDashboardUtils({
        ...dashboardUtils,
        clickIndex: dashboardUtils.dIndex,
        dIndex: index,
        show: true,
        searchDialog: false,
      });
    } else {
      console.log("setComponentIndex-2",index);
      renderUi(index);
    }
    sessionStorage.setItem('componentIndex', index.toString());
    setOpen(false);
  };

  const dispatch = useDispatch();
  const logoutUser = async () => {
    let logouturl = storeApiUrl.BACKEND_BASE_URL + LOGOUT;
    let data = {
      jcJson: {
        value_1: CART_INITIAL_STATE.apiToken,
      }
    };
    setDashboardUtils({
      ...dashboardUtils,
      isDataLoaded: false,
    });
    // console.log("logoutUser  => ", data);
    snpAxios.post(
      logouturl,
      data
    )
      .then((response) => {
        //console.log("logout response  => ",response.data);
        sessionStorage.removeItem('componentIndex');
        sessionStorage.removeItem("carInitialState");
        setLogout();
        dispatch(setCartType(CartDets.All));
        //dispatch(setActiveShopHash(INITIAL_SHOP_HASH));
        
        setDashboardUtils({
          ...dashboardUtils,
          isDataLoaded: true,
          isLogout: true,
        });
      }).catch((error) => {
        console.log("errr ", error);
        sessionStorage.removeItem('componentIndex');
        sessionStorage.removeItem("carInitialState");
        setLogout();
        dispatch(setCartType(CartDets.All));
        //dispatch(setActiveShopHash(INITIAL_SHOP_HASH));
        setDashboardUtils({
          ...dashboardUtils,
          isDataLoaded: true,
          isLogout: true,
        });
      });
    
  };


  const handleSignInKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      signIn();
    }
  };

  const restoreSignInIndex = () => {
    let index = (storeActiveShopHash.cartType === CartDets.Resto) ? DashboardComponent.BILLDETAILS : DashboardComponent.REPORTMANAGEMENT;
    if (dashboardUtils.clickIndex !== DashboardComponent.REPORTMANAGEMENT)
      index = dashboardUtils.clickIndex;
    //console.log("restoreSignInIndex",dashboardUtils.clickIndex,index);
    return index;
  }

  const signIn = () => {
    //console.log("signIn=>",dashboardUtils.oldPassWord+" "+CART_INITIAL_STATE.userPassWord)
    if (dashboardUtils.oldPassWord === CART_INITIAL_STATE.userPassWord) {
      setDashboardUtils({
        ...dashboardUtils,
        show: true,
        lockDialog: false,
        oldPassWord: "",
        isDataLoaded: true,
        dIndex: restoreSignInIndex(),
        isError: false,
        clickIndex: DashboardComponent.REPORTMANAGEMENT,
        errMsg: "",
      });
      setInactive(false);
    } else {
      setDashboardUtils({
        ...dashboardUtils,
        isError: true,
        errMsg: "Please Input Correct Password!"
      });
    }
  };
  const handleChangePwd = async () => {
    let allow = false;
    if (dashboardUtils.oldPassWord === CART_INITIAL_STATE.userPassWord) {
      if (dashboardUtils.newPassWord === CART_INITIAL_STATE.userPassWord) {
        setDashboardUtils({
          ...dashboardUtils,
          isError: true,
          errMsg: "Old Password and new Password can't be same"
        });
      } else {
        allow = true;
      }
    } else {
      setDashboardUtils({
        ...dashboardUtils,
        isError: true,
        errMsg: "You have entered wrong old password",
      });
    }
    if (allow) {
      let logouturl = storeApiUrl.BACKEND_BASE_URL + CHANGEPASSWORD;
      let data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          value_1: dashboardUtils.newPassWord,
        }
      };
      setDashboardUtils({
        ...dashboardUtils,
        isDataLoaded: false,
        passWordDialog: false,
        dIndex: (storeActiveShopHash.cartType === CartDets.Resto) ? DashboardComponent.BILLDETAILS : DashboardComponent.REPORTMANAGEMENT,
        isError: false,
        errMsg: "",
      });
      if (storeActiveShopHash.cartType === CartDets.Resto)
        sessionStorage.setItem('componentIndex', DashboardComponent.BILLDETAILS+"");
      else
        sessionStorage.setItem('componentIndex', DashboardComponent.REPORTMANAGEMENT+"");
      snpAxios.post(
        logouturl,
        data
      )
        .then((response) => {
          // console.log("response  => ",response.data);
          if (response.data.status === SUCCESS) {
            //console.log("responses => ", response.data.jcJson.prdId);
            CART_INITIAL_STATE.userPassWord = dashboardUtils.newPassWord;
            CART_INITIAL_STATE.firstTimeLogin = false;
            setDashboardUtils({
              ...dashboardUtils,
              isDataLoaded: true,
              show: false,
              showDialog: true,
              passWordDialog: false,
              subTitle: "SuccessFully Changed the Password! Please Login Again.",
              positiveAction: "Login!",
              positiveReq: true,
              negativeReq: false,
              clickIndex: DashboardComponent.LOGOUT,
            });
          }

        }).catch((error) => {
          console.log("errr ", error);
          setDashboardUtils({
            ...dashboardUtils,
            show: true,
            isDataLoaded: true,
            passWordDialog: false,
          });
        });
    }
  };

  
  

  let inactivityTimer;
  const [inactive, setInactive] = useState(false);

  const handleKeyDown = (e) => {
    //e.preventDefault();
    //console.log("handleKeyDown",storeActiveShopHash.cartType);
    if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyE') {
      setComponentIndex(DashboardComponent.EMPLOYEE)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyC') {
      setComponentIndex(DashboardComponent.CATEOGORY)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyR') {
      setComponentIndex(DashboardComponent.PRODUCTENTRY)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyB') {
      goToBilling('','',InternalBillType.TakeAway)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyP') {
      setComponentIndex(DashboardComponent.PROFILE)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyA') {
      setComponentIndex(DashboardComponent.ACCOUNTING)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyS' && storeActiveShopHash.cartType !== CartDets.Resto) {
      setComponentIndex(DashboardComponent.SEARCHDIALOG)
    } else if ((e.metaKey || (e.ctrlKey && e.altKey)) && e.code === 'KeyL') {
      setComponentIndex(DashboardComponent.LOCKDIALOG)
    }
    resetInactivityTimer();
  };
  const handleMouseMove = () => {
    //console.log("handleMouseMove");
    resetInactivityTimer();
  };

  const resetInactivityTimer = () => {
    if (!inactive && CART_INITIAL_STATE.defInactitivityTime !== -1) {
      clearTimeout(inactivityTimer);
      startInactivityTimer();
    }
    //console.log("resetInactivityTimer",dashboardUtils);

  };

  // Start the inactivity timer
  const startInactivityTimer = () => {
    //defInactitivityTime it should come greater than 1 minute
    let inactivityTime = CART_INITIAL_STATE.defInactitivityTime * 1000;
    printDebugLog("startInactivityTimer",inactivityTime);
    inactivityTimer = setTimeout(() => {
      setInactive(true);
      setComponentIndex(DashboardComponent.LOCKDIALOG);
    }, (inactivityTime)); // Set the duration of inactivity in milliseconds (e.g., 5000 = 5 seconds)
  };


  const handleWindowRefresh = () => {
    console.log("handleWindowRefresh")
    //window.location.reload();
    setComponentIndex(DashboardComponent.LOGOUT);
  };

  const handleRoleUpdate = () => {
    setDashboardUtils({
      ...dashboardUtils,
      showDialog: true,
      subTitle: "Your Role is Updated! Please SignIn Again",
      negativeAction: "Sign Out",
      outsideClickedConfirmDismiss: false,
      onHandleCancel: handleWindowRefresh,
      cancelConfirmExtra: true,
    });
  }
//https://blog.bitsrc.io/how-to-use-desktop-notifications-with-react-122536954dc2
  const inititateNotification = () => {
    if (!("Notification" in window)) {
      printInfoLog("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
      printDebugLog("Browser support notification");
    }
  };

  // function playAudio() {
  //   audioPlayer.current.play();
  // }
const KEEP_ALIVE_INTERVAL = test_environment_mode ? 5 * 60 * 1000 : 45 * 1000; // 1 minutes to 45 sec

const checkPersistence = async () => {
  if ('storage' in navigator && 'persist' in navigator.storage) {
    try {
      const persistent = await navigator.storage.persist();
      if (persistent) {
        console.log("Storage will not be cleared except by explicit user action");
      } else {
        console.log("Storage may be cleared by the UA under storage pressure.");
      }
    } catch (err) {
      console.log('Error requesting persistent storage:', err);
    }
  } else {
    console.log("Storage is not available");
  }
};

  React.useEffect(() => {
    checkPersistence(); 
    Print_Log.DEBUG_ENABLED = test_environment_mode ? true : getQueryParam('debug') === '1';
    printInfoLog("DEBUG_ENABLED in Prod",Print_Log.DEBUG_ENABLED);
    const keepAlive = () => {
      axios.get(storeApiUrl.BACKEND_BASE_URL +  'admin_snp_cart/a2z-heartbeat')
        .then(response => {
          printDebugLog('snp ping Response:', response.data);
        })
        .catch(error => {
          printInfoLog('snp ping Error:', error);
        });
    };

    const intervalId = setInterval(keepAlive, KEEP_ALIVE_INTERVAL);
    inititateNotification()
    if (!CART_INITIAL_STATE.loggedIn)
      setDashboardUtils({
        ...dashboardUtils, isLogout: true,
      })
    else {
      let index = parseInt(sessionStorage.getItem('componentIndex'));
      if (CART_INITIAL_STATE.firstTimeLogin)
        index = DashboardComponent.PASSWORDDIALOG;
      setComponentIndex(index);
      window.addEventListener('keydown', handleKeyDown);
      if (CART_INITIAL_STATE.defInactitivityTime !== -1)
        window.addEventListener('mousemove', handleMouseMove);
      const socket = new websocket.w3cwebsocket(storeApiUrl.WSBACKENED_BASE_URL + '?wsApiToken=' + CART_INITIAL_STATE.apiToken);
      socket.onopen = () => {
        console.log('WebSocket connected');
      };

      socket.onmessage = (event) => {
        console.log('Received message:', event.data);
        if (!isEmpty(event.data) && event.data.includes(':')) {
          let obj = JSON.parse(event.data);
          //console.log('Received message1:', obj);
          if (!isNumberEmpty(obj.status)) {
            if (parseInt(obj.status) === UPDATEHOMEDELIVERY) {
              CART_INITIAL_STATE.homeDelvMethod = [{}];
              CART_INITIAL_STATE.homeDelvMethod = obj.jcJson.homeDelvMethod;
              //console.log('Received message2:', CART_INITIAL_STATE);
            } else if (parseInt(obj.status) === ROLEUPDATE) {
              CART_INITIAL_STATE.roles = [{}];
              CART_INITIAL_STATE.roles = obj.jcJson.roles;
              handleRoleUpdate();
              //console.log('Received message3:', CART_INITIAL_STATE);
            } else if (parseInt(obj.status) === ORDERRECEIVED) {
              let payLoad = obj.jcJson;
              
              //playAudio();
              let mTitle = isEmpty(payLoad.title) ? "New Order Received" : payLoad.title;
              let mBody = isEmpty(payLoad.body) ? "Home Delivery" : payLoad.body;
              let billNo = isEmpty(payLoad.billNo) ? "Bill-0O-224":payLoad.billNo;

              printDebugLog('Received message: ORDERRECEIVED', obj);
              printDebugLog('Received message: ORDERRECEIVED', mTitle);
              printDebugLog('Received message: ORDERRECEIVED', mBody);
              printDebugLog('Received message: ORDERRECEIVED', billNo);

              var options = {
                body: mBody,
                icon: 'https://www.snpnextgen.com/static/media/snp_logo-1.5f6346a2.png?auto=compress&cs=tinysrgb&dpr=1&w=500',
                dir: 'ltr',
              };
          
              
               //https://www.npmjs.com/package/react-notifications
              NotificationManager.info(mTitle, <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{mBody}</p>,18000, () => {
                goToBilling({},billNo,InternalBillType.Home);
              });
              EnableSound();
              // const audio = new Audio(wavFile);
              // audio.play();

              //Old code
              // navigator.serviceWorker.register('sw.js');
              // Notification.requestPermission(function(result) {
              //   if (result === 'granted') {
              //     console.log('showNotification granted');
              //     navigator.serviceWorker.getRegistrations().then(function(registrations) {
              //       registrations[0].showNotification(mTitle, options);
              //     });
                  
              //   } else 
              //     console.log('showNotification disabled');
              // });

              // Notification request
              if (Notification.permission === 'default') {
                Notification.requestPermission().then(permission => {
                  if (permission !== 'granted') {
                    console.error('Notification permission not granted');
                  }
                });
              }
              console.log("push-notif-title: ", mTitle);
              console.log("push-notif-data: ", options);
              //Show notication and pass data to service worker
              if (navigator.serviceWorker) {
                navigator.serviceWorker.ready.then(registration => {
                  registration.showNotification(mTitle,options);
                });
              }
            }
          }
        }
        // Handle incoming messages from the WebSocket server
      };

      socket.onclose = () => {
        console.log('WebSocket disconnected');
      };

      return () => {
        socket.close();
        clearTimeout(inactivityTimer);
        clearInterval(intervalId)
        console.log("event listener removed")
        window.removeEventListener("keydown", handleKeyDown)
        if (CART_INITIAL_STATE.defInactitivityTime !== -1)
          window.removeEventListener('mousemove', handleMouseMove);
      }
    }
  }, [storeApiUrl,storeActiveShopHash]);

  const checkForRenderComponentPermission = (mIndex) => {
    let allow = true;
    if (mIndex === DashboardComponent.BILLDETAILS /*&& isRoleAllowed(TABLEOWNERRIGHTS)*/) return allow;
    const element = sideBarNavItems.find((item) => item.index === mIndex);
    if (element) {
      if (element.permission) {
        allow = isRoleAllowed(element.permission);
        if (!allow)
          showDialogBox("You Are not allowed to View " + element.title + " .Contact the Owner");
      }
    }
    return allow;
  };

  function renderUi(index) {
    //console.log("renderUi",index);
    let allow = checkForRenderComponentPermission(index);
    if (allow) {
      switch (index) {
        case DashboardComponent.REPORTMANAGEMENT:
          return (
            isFeatureAllowed([RECPMGMT,OWNERDASHBOARD]) ? 
             <OwnerDashboard /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
          )
        case DashboardComponent.EMPLOYEE:
          return (
            isFeatureAllowed(EMPMGMT) ? <Employee showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
          )
        case DashboardComponent.CATEOGORY:
          return (
            isFeatureAllowed([INVSTOCKMGMT,FOODITEMPRODUCTMGMT]) ? <Category showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
          )
        case DashboardComponent.PRODUCTENTRY:
          return (
            isFeatureAllowed([INVSTOCKMGMT,FOODITEMPRODUCTMGMT]) ? (storeActiveShopHash.cartType === CartDets.Resto) ? <RestoProductStock setComponentIndex={setComponentIndex} /> : <ProductEntry setComponentIndex={setComponentIndex} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
          )
        case DashboardComponent.SUPPLIER:
          return isFeatureAllowed(SUPMGMT) ? (isRoleAllowed(SUPPLIERCREATE) ? <SupplierInfo showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("You Are not allowed to Add Supplier Info.Contact the Owner")) : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")

        case DashboardComponent.STOCKENTRY:
          return <StockEntry setComponentIndex={setComponentIndex} />
        case DashboardComponent.CUSTOMUNIT:
          return isFeatureAllowed(CUSTOMUNITMGMT) ? (isRoleAllowed(ADMINRIGHTS) ? <Conversion showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("You Are not allowed to update Conversion.Contact the Owner")) : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.BILLDETAILS:
          return (
            isFeatureAllowed(BILLMGMT) ? <BillingTable goToBilling={goToBilling} setComponentIndex={setComponentIndex} showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
          )
        case DashboardComponent.PRODUCTSERVICECHARGE:
          return isFeatureAllowed(INVSTOCKMGMT) ? <ProductServiceCharge showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.THEME:
          return isFeatureAllowed(THEMEMGMT) ? <ThemeManager showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.CUSTOMER:
          return isFeatureAllowed(BILLMGMT) ? (isRoleAllowed(CUSTCREATE) ? <Customer showDialogBoxWithPaper={showDialogBoxWithPaper} setComponentIndex={setComponentIndex} /> : showDialogBox("You Are not allowed to Add/Update Customer.Contact the Owner")) : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.PROFILE:
          return <Profile setComponentIndex={setComponentIndex} />
        case DashboardComponent.ACCOUNTING:
          return isFeatureAllowed(ACTMGMT) ? (isRoleAllowed(ACCVIEW) ? <Accounting showDialogBoxWithPaper={showDialogBoxWithPaper} /> : showDialogBox("You Are not allowed to add/update Account Info.Contact the Owner")) : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.LOGOUTMESSAGE:
          setDashboardUtils({
            ...dashboardUtils,
            show: false,
            showDialog: true,
            iconReq: false,
            title: "Logout!",
            lockDialog: false,
            subTitle: "Do You Want to Logout",
            negativeAction: "No",
            positiveReq: true,
            clickIndex: DashboardComponent.LOGOUT,
          });
          break;
        case DashboardComponent.LOGOUT:
          logoutUser();
          break;
        case DashboardComponent.PASSWORDDIALOG:
          setDashboardUtils({
            ...dashboardUtils,
            clickIndex: dashboardUtils.dIndex,
            dIndex: index,
            show: false,
            passWordDialog: true,
          });
          break;
        case DashboardComponent.TABLECONFIG:
          return <BookTable />
        case DashboardComponent.RESTOBILLINGMODULE:
          return <RestoBillingModule setComponentIndex={setComponentIndex} />
        case DashboardComponent.SHOWBILLS:
          return <ShowBills setComponentIndex={setComponentIndex} />
        case DashboardComponent.SEARCHDIALOG:
          setDashboardUtils({
            ...dashboardUtils,
            clickIndex: dashboardUtils.dIndex,
            dIndex: index,
            show: false,
            isDataLoaded: true,
            searchDialog: true,
          });
          break;
        case DashboardComponent.LOCKDIALOG:
          setDashboardUtils({
            ...dashboardUtils,
            clickIndex: dashboardUtils.dIndex,
            dIndex: index,
            show: false,
            lockDialog: true,
            oldPassWord: "",
          });
          break;
        case DashboardComponent.COMBOOFFER:
          return isFeatureAllowed([COMBOMGMT,COMBOFOODITEMGMT]) ? <ComboOffer /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.CONFIGURATION:
          return isFeatureAllowed(CUSTOMIZEDCONFIG) ? <Configuration setComponentIndex={setComponentIndex} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.HELPMODULE:
          return <HelpModule />
        case DashboardComponent.BILLINGMANAGEMENT:
          return isFeatureAllowed(BILLMGMT) ? (isRoleAllowed(SALESRIGHTS) ? <BillingModule setComponentIndex={setComponentIndex} /> : showDialogBox("You Are not allowed to do Sales.Contact the Owner")) : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.TRACKRIDER:
            return isFeatureAllowed(CUSTOMERAPP) ? <RiderDashBoard setComponentIndex={setComponentIndex} /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.ITEMSALEDASHBOARD:
          return isFeatureAllowed(SHOWITEMWISESALES) ? <ItemSaleDashboard /> : showDialogBox("This Feature is Inactive.Contact Spark N Pass for Enable")
        case DashboardComponent.RESTOSTOCKINFO:
          return <RestoStockInformation  setComponentIndex={setComponentIndex} />
        case DashboardComponent.RETURNWASTE:
          return <ReturnWasteBill  setComponentIndex={setComponentIndex} />
        case DashboardComponent.RECEIPEMGMT:
          return <Receipe />
        default:
          console.log("renderUi default ", index);
      }
    };
  };

  const getToday = () => {
    let today = new Date()
    return today.getDate() + " " + today.toString("MMMM") + "'" + today.getFullYear();
  };
  const [isGetStockDetails, setIsGetStockDetails] = useState(false);
  const [listDataAvail, setListDataAvail] = useState(false);
  const [listStock, setListStock] = useState([]);
  const [stockDetails, setStockDetails] = useState({
    id: "",
    details: "",
    liveStockCount: "",
    price: ""
  });

  const updateUserToken = async (mToken) => {
    let UrlDetails = storeApiUrl.BACKEND_BASE_URL + "cart/update-token-fcm-user";
    let data = {
        jcJson: {
            shopHash:utilities.encode(storeActiveShopHash.id),
            apiToken:CART_INITIAL_STATE.apiToken,
            tokenId:mToken,
            platformId:51, //Hardcoded for WEB
        }
    };
    axios.post(
      UrlDetails,
      data
    )
      .then((response) => {
        console.log("updateUserToken",response.data);
      }).catch((error) => {
        console.log("error : ", error);
      });
  };

  const getProductDetails = async (stock) => {
    let productDetails = storeApiUrl.BACKEND_BASE_URL + GETPRODDETAILS + "?adminId=" + CART_INITIAL_STATE.apiToken + "&stock=" + stock + "&cart=" + storeActiveShopHash.cartType;
    let data = {

    };
    // console.log("logoutUser  => ", data);
    axios.get(
      productDetails,
      data
    )
      .then((response) => {
        //console.log(response.data);
        if (response.data.status === SUCCESS) {
          setListDataAvail(true);
          setListStock(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        console.log("error : ", error);
        //window.location.href = "#/error";
      });
  };

  const handleUpdateStockDetails = (event, option) => {
    if (option != null) {
      //console.log("handleUpdateStockDetails", event, option);
      setStockDetails({
        ...stockDetails,
        id: option.id,
        details: option.details,
        liveStockCount: option.liveStockCount,
        price: option.price
      });
      COMPONENT_PARAMS.prdId = option.id;
    }
  };
  const clearStockDetails = () => {
    setIsGetStockDetails(false);
    setStockDetails({
      ...stockDetails,
      id: "",
      details: "",
      liveStockCount: "",
      price: ""
    });
    setListStock([]);
  };
  const handleSearch = (event) => {
    const { name, value } = event.target;
    //console.log(name,value)
    if (name === 'gsd') {
      setIsGetStockDetails(true);
    } else if (name === 'lse') {
      clearStockDetails();
      setComponentIndex(DashboardComponent.STOCKENTRY);
      COMPONENT_PARAMS.isEditStock = 1;
    } else if (name === 'sb') {
      clearStockDetails();
      setComponentIndex(DashboardComponent.BILLDETAILS);
    } else {
      if (value.length >= 3) {
        clearStockDetails();
        COMPONENT_PARAMS.prdId = "";
        getProductDetails(value);
      }
    }
  };

  const handleSearchDialogClose = () => {
    //console.log("handleSearchDialogClose")
    setDashboardUtils({
      ...dashboardUtils,
      show: true,
      searchDialog: false,
      isDataLoaded: true,
      dIndex: DashboardComponent.REPORTMANAGEMENT,
    });
    //renderUi(DashboardComponent.REPORTMANAGEMENT);
    //COMPONENT_PARAMS.prdId = "";
    //clearStockDetails();
  };


  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const renderLockIcon = () => {
    return (
      <Avatar style={avatarStyle}>
        <LockOutlinedIcon />
      </Avatar>
    );
  };
  const renderLockDialogContent = () => {
    return (
      <React.Fragment>
        <div>

          <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center", width: "250px" }}>
            <Grid item md={2} xs={2}>
              {renderLockIcon()}
            </Grid>
            <Grid item md={10} xs={10}>
              <Typography variant="p" color="#02056e" fontWeight='bold' fontSize={25}>
                {CART_INITIAL_STATE.userName}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                required
                id="oldPassWord"
                label="Enter Password"
                name="oldPassWord"
                type="password"
                size="small"
                onChange={handleInputChange}
                onKeyDown={handleSignInKeyDown}
              />
            </Grid>
          </Grid>

          {dashboardUtils.isError &&
            <p className="error">{dashboardUtils.errMsg}</p>
          }
        </div>
      </React.Fragment>
    );
  };



  const renderLockDialogAction = () => {
    return (
      <React.Fragment>
        <SnpButton variant="contained" size="small" color="primary" onClick={signIn}>
          Sign In
        </SnpButton>
        <DismissButton variant="contained" size="small" color="primary" onClick={(e) => setComponentIndex(DashboardComponent.LOGOUT)}>
          Sign Out
        </DismissButton>
      </React.Fragment>
    );
  }

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <div>

          <Grid container spacing={1} sx={{ display: "flex", justifyContent: "center", width: "250px" }}>
            {
              CART_INITIAL_STATE.firstTimeLogin && <Grid item md={12}>
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word',fontSize:"14px" }}>First Login! Please Change the Password</p>
              </Grid>
            }

            <Grid item md={12}>
              <TextField
                disabled
                id="userName"
                label="User Name"
                name="userName"
                defaultValue={CART_INITIAL_STATE.userName}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                required
                id="oldPassWord"
                label="Input Old Password"
                name="oldPassWord"
                type="password"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                required
                id="newPassWord"
                label="Input New Password"
                name="newPassWord"
                type="password"
                onChange={handleInputChange}
              />
            </Grid>
            {dashboardUtils.isError &&
              <Grid item md={12} xs={12}>
                <p className="error">{dashboardUtils.errMsg}</p>
              </Grid>
            }
          </Grid>

        </div>
      </React.Fragment>
    );
  };

  const renderDialogAction = () => {
    return (
      <React.Fragment>
        <SnpButton variant="contained" color="primary" onClick={handleChangePwd}>
          Change Password
        </SnpButton>
        <DismissButton variant="contained" color="primary" onClick={handlePassWordDialogClose}>
          Dismiss
        </DismissButton>
      </React.Fragment>
    );
  };

  const getProductDetailsUI = () => {
    return (
      <React.Fragment>
        <Typography variant="p" color="#02056e" fontWeight="bold">
          {"Item Name : "}
        </Typography>
        <Typography variant="p" >
          {stockDetails.details}
        </Typography>
        <br />
        <Typography variant="p" color="#02056e" fontWeight="bold">
          {"Available Stock : "}
        </Typography>
        <Typography variant="p" >
          {stockDetails.liveStockCount}
        </Typography>
        <br />
        <Typography variant="p" color="#02056e" fontWeight="bold">
          {"Selling Price(Exl. GST) : "}
        </Typography>
        <Typography variant="p" >
          {stockDetails.price}
        </Typography>
        <br />
      </React.Fragment>
    )
  };

  const renderSearchContent = () => {
    return (<React.Fragment>
      <div>
        <Box sx={{ display: "flex" }}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Autocomplete id="search-select"
                options={listStock}
                autoHighlight
                variant="filled"
                size="small"
                onChange={handleUpdateStockDetails}
                fullWidth
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.details}
                defaultValue={stockDetails}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.details}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Here"
                    onChange={handleSearch}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            {
              isGetStockDetails && <div style={{ alignItems: "center" }}>
                <div style={{ textAlign: "center", marginLeft: 50 }}>{getProductDetailsUI()}</div>
              </div>
            }
          </Grid>
        </Box>
      </div>
    </React.Fragment>);
  };



  const renderSearchDialogAction = () => {
    return (
      <React.Fragment>

        <Box sx={{ display: "flex", width: "100%", maxWidth: "none" }}>

          <Grid item md={12} xs={12}>
            <Button id="gsd"
              name="gsd" disabled={!listDataAvail} variant="contained" color="primary" onClick={handleSearch} sx={{
                mx: 2
              }}>
              Get Stock Details
            </Button>
            <Button id="lse"
              name="lse" disabled={!listDataAvail} variant="contained" color="primary" onClick={handleSearch} sx={{
                mr: 2
              }}>
              Live Stock Entry
            </Button>
            <Button id="sb"
              name="sb" disabled={!listDataAvail} variant="contained" color="primary" onClick={handleSearch} sx={{
                mr: 2
              }}>
              Start Billing
            </Button>
          </Grid>

        </Box>

      </React.Fragment>
    );
  };

  const checkLogout = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const handleLoader = (loaded) => {
    setDashboardUtils({
      ...dashboardUtils,isDataLoaded : loaded,
    });
  }

  const [notification, setNotification] = useState({
    message: '',
    show: false,
  });

  const triggerNotification = () => {
    let val = notification.show;
    setNotification({
      message: '',
      show: !val,
    });
  };

  const EnableSound = () => {
    setNotification({
      message: '',
      show: true,
    });
    // Hide the notification after 5 seconds
    setTimeout(() => {
      setNotification({
        message: '',
        show: false,
      });
    }, 1000);
  };

  return (
    <React.Fragment>
      <NotificationContainer/>
      <NotificationSound message={notification.message} show={notification.show} />
      {/* <audio ref={audioPlayer} src={NotificationSound} /> */}
      {
        dashboardUtils.isDataLoaded ?
          dashboardUtils.isLogout ? 
          offline_enable ?  checkLogout() :<Navigate replace to="/login" /> :
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <Navbar open={open} toggleDrawer={toggleDrawer} setComponentIndex={setComponentIndex} currIndex={dashboardUtils.dIndex} handleLoader={handleLoader} />
              <Sidebar
                open={open}
                toggleDrawer={toggleDrawer}
                setComponentIndex={setComponentIndex}
              />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  overflow: "auto",

                }}
              >
                <br />
                <Container maxWidth="none" sx={{ mt: 6, mb: 6 }}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      {
                        dashboardUtils.show &&
                          <div
                            elevation={0}
                          >
                            {/* <button onClick={triggerNotification}>Trigger Notification</button> */}
                            {renderUi(dashboardUtils.dIndex)}
                          </div>
                      }
                    </Grid>
                  </Grid>
                </Container>

                {
                  dashboardUtils.showDialog && <ConfirmDialog isOpen={dashboardUtils.showDialog}
                    title={dashboardUtils.title}
                    subTitle={dashboardUtils.subTitle}
                    negativeAction={dashboardUtils.negativeAction}
                    positiveReq={dashboardUtils.positiveReq}
                    onClose={handleDialogClose}
                    iconReq={dashboardUtils.iconReq}
                    onCancel={dashboardUtils.onHandleCancel}
                    cancelExtra={dashboardUtils.cancelConfirmExtra}
                    outsideClickedDismiss={dashboardUtils.outsideClickedConfirmDismiss}
                    onSubmit={() => setComponentIndex(DashboardComponent.LOGOUT)}
                  ></ConfirmDialog>}
                {dashboardUtils.passWordDialog && <DialogBox
                  showDialog={dashboardUtils.passWordDialog}
                  title="Change Password"
                  onClose={handlePassWordDialogClose}
                  dialogContent={renderDialogContent()}
                  dialogActions={renderDialogAction()}
                ></DialogBox>}
                {dashboardUtils.lockDialog && <DialogBox
                  showDialog={dashboardUtils.lockDialog}
                  title="Unlock"
                  iconReq={false}
                  outsideClickedDismiss={false}
                  dialogContent={renderLockDialogContent()}
                  dialogActions={renderLockDialogAction()}
                ></DialogBox>}
                {dashboardUtils.searchDialog && <DialogBox
                  showDialog={dashboardUtils.searchDialog}
                  title="Search By Item or Brand Name"
                  onClose={handleSearchDialogClose}
                  dialogContent={renderSearchContent()}
                  dialogActions={renderSearchDialogAction()}
                ></DialogBox>}
              </Box>
            </Box> : <Loader />
      }
    </React.Fragment>
  );
}
